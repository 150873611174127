import { TableHeader, TableHeaderCell } from 'components/atoms/Table'
import { TableSortButton } from 'components/atoms/Table/TableSortButton'
import { Selector, TableColumn } from './types'
import { SortState } from './hooks/useDatatableSort'
import { QuestionIcon } from 'components/atoms/Icons'
import { Tooltip } from 'components/atoms/Tooltip'

interface Props<Row> {
  columns: TableColumn<Row>[]
  updateSortKey: (selector: Selector<Row>, sortKey: string, customSort?: (a: Row, b: Row) => number) => void
  sortState: SortState<Row>
}

export function DataTableHeader<Row>({ columns, updateSortKey, sortState }: Props<Row>) {
  const { descending, sortKey } = sortState
  return (
    <TableHeader>
      {columns.map(({ name, sortable, selector, customSort, side, maxWidth, nameTooltip }, i) => (
        <TableHeaderCell side={side} key={i} maxWidth={maxWidth}>
          {sortable !== false ? (
            <TableSortButton
              onClick={() => updateSortKey(selector, name, customSort)}
              isSorting={sortKey === name}
              descending={!descending}
            >
              <>
                {name}
                {nameTooltip && (
                  <Tooltip tooltip={nameTooltip}>
                    <QuestionIcon />
                  </Tooltip>
                )}
              </>
            </TableSortButton>
          ) : (
            name
          )}
        </TableHeaderCell>
      ))}
    </TableHeader>
  )
}
