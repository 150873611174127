import { RefObject, useCallback, useEffect, useState } from 'react'
import { useLayout } from 'providers/LayoutProvider'

type WrapperHeight = 'fixed' | 'auto'

export const useTableSize = (
  dataTableContainerRef: RefObject<HTMLDivElement>,
  tableTitleSectionRef: RefObject<HTMLDivElement>,
  fitToViewport = true,
) => {
  const [wrapperHeight, setWrapperHeight] = useState<WrapperHeight>('auto')
  const [distanceFromTop, setDistanceFromTop] = useState(0)
  const [tableTopDistance, setTableTopDistance] = useState<number | undefined>(0)
  const { mainRef } = useLayout()

  const checkTableSize = useCallback(() => {
    if (!fitToViewport) return
    const dataTableContainer = dataTableContainerRef.current
    const tableTitleSection = tableTitleSectionRef.current
    const mainTopDistance = mainRef?.current?.getBoundingClientRect().top

    setTableTopDistance(dataTableContainer?.getBoundingClientRect().top)

    const tableHeight = dataTableContainer?.getBoundingClientRect().height
    const tableTitleSectionHeight = tableTitleSection?.getBoundingClientRect().height ?? 0
    const screenHeight = window.innerHeight

    const isWrapperHeightFixed = wrapperHeight === 'fixed'
    const isTableHeightBiggerThanScreen = tableHeight && tableHeight > screenHeight

    if (isTableHeightBiggerThanScreen && !isWrapperHeightFixed) {
      setWrapperHeight('fixed')
    }

    if (mainTopDistance && tableTopDistance) {
      const totalTableDistanceFromTop = mainTopDistance + tableTopDistance - tableTitleSectionHeight
      setDistanceFromTop(totalTableDistanceFromTop)
    }
  }, [dataTableContainerRef, mainRef, tableTitleSectionRef, tableTopDistance, wrapperHeight, fitToViewport])

  useEffect(() => {
    if (!mainRef?.current) {
      return
    }

    const resizeObserver = new ResizeObserver(checkTableSize)
    const observer = new MutationObserver(checkTableSize)

    observer.observe(mainRef.current, {
      subtree: true,
      childList: true,
    })

    resizeObserver.observe(mainRef.current)

    return () => {
      observer.disconnect()
      resizeObserver.disconnect()
    }
  }, [mainRef, checkTableSize])

  return { wrapperHeight, distanceFromTop }
}
