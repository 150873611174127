import { DropdownButton, DropdownMenu, DropdownMenuItem, DropdownWrapper } from 'components/atoms/Dropdown'
import styled from 'styled-components'

export interface Props<Option extends string> {
  currentOption: Option
  id: string
  options: readonly Option[]
  setCurrentOption: (option: Option) => void
}

export const TableFilter = <Option extends string>({ currentOption, options, setCurrentOption, id }: Props<Option>) => {
  return (
    <Wrapper>
      <Label htmlFor={id}>Show:</Label>
      <StyledDropdown
        options={options as unknown as string[]}
        value={currentOption}
        id={id}
        onChange={setCurrentOption as (option: string) => void}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

const Label = styled.label`
  color: ${({ theme }) => theme.colors.Text0};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

const StyledDropdown = styled(DropdownMenu)`
  width: unset;

  ${DropdownWrapper} {
    border: 1px solid ${({ theme }) => theme.colors.Mouse};
    border-radius: 4px;
  }

  ${DropdownButton} {
    padding: 5px 8px 5px 16px;
    height: unset;
    width: 126px;
    font-size: 14px;
    line-height: 20px;
  }

  ${DropdownMenuItem} {
    padding: 11.5px 0 10px 12px;
  }
`
