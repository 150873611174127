import {
  TableExpandedRowCell,
  TableBody,
  TableCell,
  TableCellExpanded,
  TableRow,
  TableRowExpanded,
} from 'components/atoms/Table'
import { Fragment, Key, ReactNode } from 'react'
import styled from 'styled-components'
import { TableColumn, TableRow as TableRowType } from './types'

interface Props<Row> {
  columns: TableColumn<Row>[]
  data: TableRowType<Row>[]
  isRowSelected?: (row: Row) => boolean
  expandingNode?: ReactNode
  expandingHeight: string
  disableHoverLinkBehavior: boolean
}

export function DataTableBody<Row>({
  columns,
  data,
  isRowSelected,
  expandingNode,
  expandingHeight,
  disableHoverLinkBehavior,
}: Props<Row>) {
  const isSelected = (row: Row) => !!(isRowSelected && expandingNode && isRowSelected(row))

  return (
    <TableBody>
      {data.map((row, rowKey) => (
        <Fragment key={row.key ?? getRowKey(row, rowKey)}>
          <TableRow
            disablePointerCursor={disableHoverLinkBehavior}
            disableShadow={disableHoverLinkBehavior || isSelected(row)}
          >
            {columns.map(({ cell, selector, href, blank, side, maxWidth }, cellKey) => (
              <TableCell
                side={side}
                href={href?.(row)}
                blank={blank}
                key={cellKey}
                selected={isSelected(row) || (row.expandRow?.content as boolean)}
                maxWidth={maxWidth}
              >
                {cell ? cell(row) : selector(row).toString()}
              </TableCell>
            ))}
          </TableRow>
          {isSelected(row) && (
            <TableRowExpanded>
              <TableCellExpanded height={expandingHeight} colSpan={columns.length}>
                <ExpandingNodeWrapper>{expandingNode}</ExpandingNodeWrapper>
              </TableCellExpanded>
            </TableRowExpanded>
          )}
          {row.expandRow && (
            <TableRow>
              <TableExpandedRowCell colSpan={columns.length} height={row.expandRow.rowHeight}>
                <ExpandingNodeWrapper>{row.expandRow.content}</ExpandingNodeWrapper>
              </TableExpandedRowCell>
            </TableRow>
          )}
        </Fragment>
      ))}
    </TableBody>
  )
}

const getRowKey = (row: object, index: number): Key => {
  if ('id' in row && (typeof row.id === 'string' || typeof row.id === 'number') && row.id) {
    return row.id
  }
  return index
}

const ExpandingNodeWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
`
