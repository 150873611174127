import styled from 'styled-components'
import { Button, LinkButtonStylesHover } from 'components/atoms/Button'
import { Text } from 'components/atoms/Text'

interface ShowMoreButtonProps {
  dataToDisplay: number
  dataLength: number
  onClick: () => void
  disabled: boolean
}

export const ShowMoreButton = ({ dataToDisplay, dataLength, onClick, disabled }: ShowMoreButtonProps) => {
  return (
    <ShowMoreWrapper>
      <Spacer />
      <StyledButton onClick={onClick} view="link" disabled={disabled}>
        Show More
      </StyledButton>
      <NumberDisplayedText color="light">
        {dataToDisplay} of {dataLength}
      </NumberDisplayedText>
    </ShowMoreWrapper>
  )
}

const Spacer = styled.div``

const ShowMoreWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: baseline;
`

const NumberDisplayedText = styled(Text)`
  justify-self: flex-end;
`

const StyledButton = styled(Button)`
  text-decoration: underline;
  ${LinkButtonStylesHover};
`
