import styled from 'styled-components'
import { DataTableBody } from './DataTableBody'
import { DataTableHeader } from './DataTableHeader'
import { DatatableProps } from './types'
import { ShowMoreButton } from 'components/molecules/ShowMoreButton'
import { Table, TableContainer } from 'components/atoms/Table'
import { TableFilter } from 'components/molecules/TableFilter'
import { Text } from 'components/atoms/Text'
import { useDatatableSort } from './hooks/useDatatableSort'
import { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useSortedData } from './hooks/useSortedData'
import { useWidthBreakpoint, useTableSize } from 'hooks'
import { InfoBillboard } from 'components/molecules/InfoBillboard'
import { CurrencyExchangeIcon } from 'components/atoms/Icons'
import { Button } from 'components/atoms/Button'

const DEFAULT_ROWS_TO_DISPLAY = 5

export function Datatable<T>({
  columns,
  data,
  initSortKey,
  initSortOrder = 'descending',
  isRowSelected,
  expandingNode,
  expandingHeight = '267px',
  title,
  filters,
  initFilter,
  emptyStateMessage,
  fitToViewport = true,
  disableHoverLinkBehavior = false,
}: DatatableProps<T>) {
  const isTabletOrSmaller = useWidthBreakpoint('tabletAndSmaller')
  const sortColumn = columns.find((column) => column.name === initSortKey) ?? columns[0]
  const { sortState, updateSortKey } = useDatatableSort({
    sortKey: sortColumn.name,
    selector: sortColumn.selector,
    customSort: sortColumn.customSort,
    descending: initSortOrder === 'descending',
  })
  const initialCounter = data.length < DEFAULT_ROWS_TO_DISPLAY ? data.length : DEFAULT_ROWS_TO_DISPLAY
  const [rowsToDisplay, setRowsToDisplay] = useState(initialCounter)
  const [currentFilter, setCurrentFilter] = useState(initFilter)

  const sortedData = useSortedData(isTabletOrSmaller ? data.slice(0, rowsToDisplay) : data, sortState)

  const filteredAndSortedData = useMemo(() => {
    if (!currentFilter || !filters) {
      return sortedData
    }
    const filter = filters[currentFilter]

    return sortedData.filter(filter)
  }, [currentFilter, filters, sortedData])

  const filterOptions = (filters && Object.keys(filters)) ?? []
  const resetFilter = useCallback(() => setCurrentFilter(initFilter), [initFilter])
  const showMoreButtonHandler = () => {
    if (data.length % DEFAULT_ROWS_TO_DISPLAY === 0) {
      return setRowsToDisplay((current) => current + DEFAULT_ROWS_TO_DISPLAY)
    }
    return setRowsToDisplay((current) => current + (data.length - current))
  }

  const dataTableContainerRef = useRef<HTMLDivElement>(null)
  const tableTitleSectionRef = useRef<HTMLDivElement>(null)

  const { wrapperHeight, distanceFromTop } = useTableSize(dataTableContainerRef, tableTitleSectionRef, fitToViewport)

  useEffect(() => {
    setRowsToDisplay(data.length < DEFAULT_ROWS_TO_DISPLAY ? data.length : DEFAULT_ROWS_TO_DISPLAY)
  }, [data.length])

  return (
    <DatatableWrapper wrapperHeight={wrapperHeight} distanceFromTop={distanceFromTop}>
      {title && (
        <TableTitleSection ref={tableTitleSectionRef}>
          <Text variant="title3" color="dark" extraBold>
            {title}
          </Text>
          {currentFilter && (
            <TableFilter
              currentOption={currentFilter}
              id="table-filter"
              options={filterOptions}
              setCurrentOption={setCurrentFilter}
            />
          )}
        </TableTitleSection>
      )}
      <DatatableContainer ref={dataTableContainerRef}>
        {!!filteredAndSortedData.length && (
          <Table>
            <DataTableHeader updateSortKey={updateSortKey} sortState={sortState} columns={columns} />
            <DataTableBody
              data={filteredAndSortedData}
              columns={columns}
              isRowSelected={isRowSelected}
              expandingNode={expandingNode}
              expandingHeight={expandingHeight}
              disableHoverLinkBehavior={disableHoverLinkBehavior}
            />
          </Table>
        )}
        {!filteredAndSortedData.length && emptyStateMessage && (
          <InfoBillboard
            title={emptyStateMessage}
            icon={CurrencyExchangeIcon}
            buttons={
              <Button view="primary" onClick={resetFilter}>
                Show All
              </Button>
            }
          />
        )}
      </DatatableContainer>
      {isTabletOrSmaller && (
        <ShowMoreButton
          dataToDisplay={rowsToDisplay}
          dataLength={data.length}
          onClick={showMoreButtonHandler}
          disabled={rowsToDisplay === data.length}
        />
      )}
    </DatatableWrapper>
  )
}

export const DatatableWrapper = styled.div<{
  wrapperHeight?: string
  distanceFromTop?: number
}>`
  display: flex;
  flex-direction: column;
  height: ${({ wrapperHeight, distanceFromTop }) =>
    wrapperHeight === 'fixed' ? `calc(100vh - ${distanceFromTop}px)` : 'auto'};

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    min-height: 70vh;
  }
`

const DatatableContainer = styled(TableContainer)`
  overflow-x: auto;

  @media ${({ theme }) => theme.queries.desktopAndSmaller} {
    width: calc(100% + 40px);
  }

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    width: calc(100% + 24px);
  }

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    width: calc(100% + 16px);
  }
`

const TableTitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 0.3s ease-in-out;
  padding-bottom: 16px;
`
