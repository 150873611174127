import styled, { css } from 'styled-components'
import { Column } from 'components/atoms/Column'
import { Text } from 'components/atoms/Text'
import { ReactElement, ReactNode } from 'react'
import { IconProps } from 'components/atoms/Icons'

export interface InfoBillboardProps {
  title: string
  text?: string
  buttons?: ReactNode
  icon: (props: IconProps) => ReactElement
  minHeight?: number
  description?: ReactNode
}

export const InfoBillboard = ({ title, text, buttons, icon: Icon, minHeight, description }: InfoBillboardProps) => (
  <Wrapper minHeight={minHeight}>
    <Icon size={48} />
    <h3>{title}</h3>
    {text && <Text color="light">{text}</Text>}
    {description && <StyledText color="light">{description}</StyledText>}
    {buttons}
  </Wrapper>
)

export const Wrapper = styled(Column)<{ minHeight?: number }>`
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  width: 100%;
  padding: 56px;
  border: 1px solid ${({ theme }) => theme.colors.Iron};
  color: ${({ theme }) => theme.colors.CopperOrange};
  text-align: center;
  ${({ minHeight }) =>
    minHeight
      ? css`
          min-height: ${minHeight}px;
        `
      : ''}
`

const StyledText = styled(Text)`
  max-width: 675px;
  text-align: center;
`
