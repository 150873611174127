import * as Select from '@radix-ui/react-select'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { BorderRadiuses, Transitions } from 'styles'
import { Row } from 'components/atoms/Row'
import { Img } from '../Image'
import { StaticImageData } from 'next/image'

export interface Props {
  value: string
  text: string | ReactNode
  icon?: string | StaticImageData
  disabled?: boolean
}

export const DropdownItem = ({ value, text, icon, disabled }: Props) => {
  return (
    <DropdownMenuItem value={value} disabled={disabled}>
      <Select.ItemText>
        <ItemContent>
          {icon && <Img image={icon} size={24} />}
          {text}
        </ItemContent>
      </Select.ItemText>
    </DropdownMenuItem>
  )
}

export const ItemContent = styled(Row)`
  max-width: 100%;
  gap: 6px;
`

export const DropdownMenuItem = styled(Select.Item)`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.White};
  border: none;
  border-radius: ${BorderRadiuses.s};
  outline: none;
  cursor: default;
  transition: ${Transitions.all};

  &:hover {
    outline: none;
    background-color: ${({ theme }) => theme.colors.GrayNurse};
    cursor: pointer;
  }

  &[data-disabled] {
    color: ${({ theme }) => theme.colors.Iron};
    cursor: not-allowed;
  }
`
