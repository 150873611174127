import { Icon, IconProps } from '../Icon'
import { IconContainer } from '../IconBase'
import { forwardRef } from 'react'

interface ArrowIconProps extends IconProps {
  className?: string
}

export const ArrowDropDownIcon = forwardRef<HTMLDivElement, ArrowIconProps>(function ArrowDropDownIcon(
  { color, size, className }: ArrowIconProps,
  ref,
) {
  return (
    <IconContainer color={color} size={size} className={className} ref={ref}>
      <Icon color={color} size={size}>
        arrow_drop_down
      </Icon>
    </IconContainer>
  )
})
