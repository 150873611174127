import { useMemo } from 'react'
import { SortState } from './useDatatableSort'
import { Selector } from '../types'
import { BigNumber } from 'ethers'
import { compareAsc } from 'date-fns'

const getDefaultSortFn = <T>(selector: Selector<T>, descending = true) => {
  return (a: T, b: T) => {
    const aValue = descending ? selector(b) : selector(a)
    const bValue = descending ? selector(a) : selector(b)

    if (typeof aValue != typeof bValue) return 0
    switch (typeof aValue) {
      case 'number':
        return aValue - (bValue as number)
      case 'boolean':
        if (aValue === bValue) return 0
        return aValue ? 1 : -1
      case 'object':
        if (BigNumber.isBigNumber(aValue) && BigNumber.isBigNumber(bValue)) {
          return aValue.sub(bValue).toNumber()
        }
        if (aValue instanceof Date && bValue instanceof Date) {
          return compareAsc(aValue, bValue)
        }
        throw new Error('Sorting only implemented for BigNumber & Dates objects')
    }
    return aValue.toString().localeCompare(bValue.toString())
  }
}

export function useSortedData<T>(data: T[], sortState: SortState<T>) {
  const sortedData = useMemo(() => {
    const { customSort, selector } = sortState
    return data.sort(customSort ?? getDefaultSortFn(selector, sortState.descending))
  }, [data, sortState])

  return sortedData
}
