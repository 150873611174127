import styled, { css } from 'styled-components'
import { BorderRadiuses, zIndexes } from 'styles'
import { Row } from 'components/atoms/Row'
import { ReactNode } from 'react'

interface TableHeaderCellProps {
  children?: ReactNode
  colspan?: number
  className?: string
  onClick?: () => void
  side?: 'left' | 'right' | 'center'
  maxWidth?: number
}

export const TableHeaderCell = ({ children, colspan, className, onClick, side, maxWidth }: TableHeaderCellProps) => (
  <TableHeaderCellStyled maxWidth={maxWidth} colSpan={colspan} className={className} onClick={onClick}>
    <HeaderCellRow side={side}>{children}</HeaderCellRow>
  </TableHeaderCellStyled>
)

export const TableHeaderCellStyled = styled.th<{ maxWidth?: number }>`
  height: 40px;
  padding: 8px 8px 8px 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.Corduroy};
  border-top: 1px solid ${({ theme }) => theme.colors.Mouse};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Mouse};
  background-color: ${({ theme }) => theme.colors.White};
  position: sticky;
  top: 0;
  z-index: ${zIndexes.aboveContent};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      width: ${maxWidth + 'px'};
      max-width: ${maxWidth + 'px'};
      text-overflow: ellipsis;
    `}

  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.colors.Mouse};
    border-radius: ${BorderRadiuses.s} 0px 0px 0px;
  }

  &:last-child {
    border-right: 1px solid ${({ theme }) => theme.colors.Mouse};
    border-radius: 0px ${BorderRadiuses.s} 0px 0px;
  }
`
const HeaderCellRow = styled(Row)<Pick<TableHeaderCellProps, 'side'>>`
  justify-content: ${({ side }) => {
    switch (side) {
      case 'left':
      default:
        return 'flex-start'
      case 'right':
        return 'flex-end'
      case 'center':
        return 'center'
    }
  }};
`
